import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { endOfDay, startOfDay, sub } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import * as queries from '@/graphql/queries';
import { TUseOpenModal } from '@/hooks/useOpenModal';
import { useDebounce } from '@/hooks';
import { handlePlotlyResize } from '@/helpers/plotly';
import '@/styles/datepicker.scss';
import { useSearchParams } from 'react-router-dom';

import Modal from '@/components/common/Modal';
import NoDataFound from '@/components/common/NoDataFound';

import { formatDateTime } from '@/pages/Home/helpers';

import usePlotProxy from '@/hooks/usePlotProxy';
import { arrToMapByKeys, capitalizeFirstLetter, toFixed } from '@/helpers';
import usePaginatedQuery from '@/hooks/fetchNextTokenFromApollo';
import { getMinMax } from '@/helpers/arrays';
import { getAxisGap } from '@/helpers/axisScaleHelper/helpers';

import styles from './SensorByTelemetryModal.module.scss';
import DateRange from '../DateRange';
import { getInstrumentChartDefaultLayout } from './helpers';

const cn = classnames.bind(styles);

type TTelemetryModalProps = Pick<TUseOpenModal, 'isOpen' | 'onClose'> & {
  instrumentId: string;
  instrumentName: string;
  selectedSensor: TTelemetryByInstrument;
};

const currentDate = endOfDay(new Date());
const defaultStartDate = startOfDay(sub(currentDate, { days: 90 }));

const SensorByTelemetryModal: FC<TTelemetryModalProps> = ({
  isOpen,
  onClose,
  instrumentId,
  selectedSensor,
  instrumentName,
}) => {
  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const [searchParams] = useSearchParams();

  const preselectedStartDate = searchParams.get('startDate') ?? null;
  const preselectedEndDate = searchParams.get('endDate') ?? null;

  const [startDate, setStartDate] = useState<Nullable<Date>>(
    preselectedStartDate ? new Date(preselectedStartDate) : defaultStartDate
  );
  const [endDate, setEndDate] = useState<Nullable<Date>>(
    preselectedEndDate ? new Date(preselectedEndDate) : currentDate
  );

  const onChangeStartDate = useCallback((date: Nullable<Date>) => {
    if (!date) return;

    setStartDate(date);
  }, []);
  const onChangeEndDate = useCallback((date: Nullable<Date>) => {
    if (!date) return;
    setEndDate(date);
  }, []);

  const requestPayload = useMemo(
    () => ({
      instrumentId,
      name: selectedSensor.name,
      endDate: endDate?.toISOString() ?? null,
      startDate: startDate?.toISOString() ?? null,
      limit: 100,
      startToken: null,
    }),
    [instrumentId, selectedSensor.name, endDate, startDate]
  );

  const debouncedRequestPayload = useDebounce(requestPayload, 300);

  const {
    allItems: data,
    loading: isLoading,
    error: isError,
  } = usePaginatedQuery<TTelemetryBySensorFromServer>(
    queries.telemetryBySensor,
    debouncedRequestPayload,
    (res) => res?.telemetryBySensor,
    'telemetryBySensor'
  );

  const isEmptyData = useMemo(() => !isLoading && !data?.length, [isLoading, data?.length]);
  const debouncedIsEmptyData = useDebounce(isEmptyData);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  const xValues = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];
    return sensorData.map((sensor) => formatDateTime(sensor?.time, 'yyyy-MM-dd HH:mm'));
  }, [data]);

  const yValues = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];

    return sensorData.map((sensor) => {
      if (sensor?.value && sensor?.type === 'string') {
        return capitalizeFirstLetter(String(sensor.value).toLowerCase() ?? '').replace('_', ' ');
      }

      return toFixed(Number(sensor?.value));
    });
  }, [data]);

  const isStringYAxis = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];

    return sensorData?.every((sensor) => sensor?.type === 'string');
  }, [data]);

  const yAxisRange = useMemo((): [number, number] => {
    if (isStringYAxis) {
      const mapByKey = arrToMapByKeys(data ?? [], 'value');
      // consider all unique string values when forming a range
      const maxRange = Object.keys(mapByKey).length - 1;
      return [-0.1, maxRange + 0.1];
    }
    const { min, max } = getMinMax(yValues as number[]);
    const valDiff = max - min;
    const gap = getAxisGap(valDiff);

    return [min - gap, max + gap];
  }, [isStringYAxis, yValues, data]);

  const yAxisSuffix = useMemo(() => {
    const sensorData: TTelemetryBySensorFromServer[] = data ?? [];
    if (!sensorData.length) return '';

    return ` ${sensorData[0].postfix}`;
  }, [data]);

  useEffect(() => {
    if (!graphRef.current) return;

    if (isEmptyData) {
      plotlyProxy.purge();
      return;
    }

    const trace = {
      x: xValues,
      y: yValues,
      mode: 'lines+markers',
      type: 'scatter',
      line: {
        color: '#56e5f1',
        width: 2,
        shape: isStringYAxis ? 'hv' : 'linear',
      },
    };

    const layout = getInstrumentChartDefaultLayout({
      isStringYAxis,
      selectedSensor,
      yAxisRange,
      yAxisSuffix,
    });

    plotlyProxy.react(
      [trace],
      layout,
      {
        scrollZoom: true,
        autosize: true,
        displayModeBar: false,
      },
      () => {
        handlePlotlyResize();
      }
    );
  }, [
    graphRef.current,
    xValues,
    yValues,
    isLoading,
    isEmptyData,
    isStringYAxis,
    yAxisRange,
    selectedSensor.label,
    selectedSensor.postfix,
  ]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} shouldCloseOnOverlayClick className={cn('modal')}>
      <Modal.Header onRequestClose={onClose} className={cn('modal__header')}>
        <div className={cn('modal__header-titles')}>
          <span className={cn('modal__header-title')}>{selectedSensor.label}</span>
          <span className={cn('modal__header-instrument')}>{instrumentName}</span>
        </div>
        <DateRange
          startDate={startDate}
          onChangeStartDate={onChangeStartDate}
          endDate={endDate}
          onChangeEndDate={onChangeEndDate}
        />
      </Modal.Header>
      <Modal.Content className={cn('modal__content')}>
        {isLoading && <Skeleton className={cn('skeleton')} />}
        <div ref={graphRef} id="sensor-by-telemetry-chart" className={cn('modal__chart')}>
          {!isLoading && debouncedIsEmptyData && (
            <NoDataFound
              className={cn('no-data')}
              alignment="center"
              size="normal"
              textData={
                isError ? (
                  'Something went wrong'
                ) : (
                  <span>
                    No Data <br /> Try to change the date range
                  </span>
                )
              }
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default memo(SensorByTelemetryModal);
