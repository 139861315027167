import { LAYOUT_CONFIG } from '@/pages/Dataset/constants';
import { DEFAULT_TITLE_LAYOUT } from '@/components/charts/DownloadChartButton/prepareLayouts';

export const getInstrumentChartDefaultLayout = ({
  isStringYAxis,
  selectedSensor,
  yAxisRange,
  yAxisSuffix,
}: {
  isStringYAxis: boolean;
  selectedSensor: TTelemetryByInstrument;
  yAxisRange: Nullable<[number, number]>;
  yAxisSuffix: string;
}) => {
  const postfixString = selectedSensor.postfix ? `(${selectedSensor.postfix})` : '';

  return {
    ...LAYOUT_CONFIG(),
    yaxis: {
      ...LAYOUT_CONFIG().yaxis,
      griddash: 'dot',
      showgrid: !isStringYAxis,
      color: '#000000',
      ticksuffix: yAxisSuffix,
      fixedrange: true,
      range: yAxisRange,
      autorange: false,
      title: {
        ...LAYOUT_CONFIG().yaxis.title,
        text: `${selectedSensor.label} ${postfixString}`,
        standoff: 20,
        font: {
          ...DEFAULT_TITLE_LAYOUT,
          size: 17,
        },
      },
      automargin: true,
    },
    xaxis: {
      ...LAYOUT_CONFIG().xaxis,
      color: '#000000',
      nticks: 20,
      type: 'category',
      title: {
        ...LAYOUT_CONFIG().xaxis.title,
        text: 'Time',
        standoff: 20,
        font: {
          ...DEFAULT_TITLE_LAYOUT,
          size: 17,
        },
      },
      autorange: true,
      tickangle: -45,
      automargin: true,
    },
    margin: {
      l: isStringYAxis ? 90 : 70,
      r: 0,
      t: 0,
      b: 20,
      pad: 0,
    },
    dragmode: true,
  };
};
